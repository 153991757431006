import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./form.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const HookFormExchange = ({ setIsProfile }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  
  const [loading,setLoading] = useState(false); 

  const { slug } = useParams();
  console.log(slug)

  const onSubmit = async (data) => {
    console.log("Form Data: ", data);
    const {first_name,last_name,email,phone,work,job_title,address,notes} = data
    const formObj = {
      expert_id:slug,
      first_name,
      last_name,
      // fullName: "dsds",
      email,
      phone_number: phone,
      work,
      job_title,
      address,
      notes,
    };
    try{
      setLoading(true)
      await axios
      .post(`${process.env.REACT_APP_GET_EXPERT_PROFILE}create-lead-form-data`, formObj, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsProfile(null);
      setLoading(false)
    }catch(err){
      console.log(err);
      setLoading(false);
    }
    // reset(); // Reset form fields after submission
  };

  const fields = [
    {
      name: "first_name",
      field: "input",
      label: "First Name",
      others: {
        required: "First name is required",
        minLength: {
          value: 3,
          message: "At least 3 characters required",
        },
      },
    },
    {
      name: "last_name",
      field: "input",
      label: "Last Name",
      others: {
        required: "Last name is required",
        minLength: {
          value: 3,
          message: "At least 3 characters required",
        },
      },
    },
    {
      field: "input",
      label: "Email",
      name: "email",
      others: {
        // required: "Email is required",
        pattern: {
          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          message: "Invalid email address",
        },
      },
    },
    {
      name: "phone",
      field: "input",
      label: "Phone Number",
      type: "number",
      others: {
        // required: "Phone number is required",
        pattern: {
          value: /^[0-9]{10}$/,
          message: "Phone number must be 10 digits",
        },
      },
    },
    {
      name: "work",
      field: "input",
      label: "Work",
      others: {
        // // required: "Job title is required",
      },
    },
    {
      name: "job_title",
      field: "input",
      label: "Job Title",
      others: {
        // // required: "Job title is required",
      },
    },
    {
      name: "address",
      field: "textarea",
      label: "Address",
      textarea: true,
      others: {
        maxLength: { value: 200, message: "Max 200 characters allowed" },
      },
    },
    {
      name: "notes",
      field: "textarea",
      label: "Message",
      textarea: true,
      others: {
        // // required: "Message is required",
        maxLength: { value: 200, message: "Max 200 characters allowed" },
      },
    },
  ];

  if (loading) {
    return (
      <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-purple-700 border-8 h-52 w-52"></div>
      </div>
    );
  }

  return (

    <div style={{ margin: "0 auto" }}>
      <h1 className="text-center text-lg font-bold py-3 sticky top-0 bg-white">
        Exchange your information
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col react-form border border-white p-1 rounded-md"
      >
        {fields.map((item) => (
          <div key={item.name}>
            <label>{item.label}{item.others?.required ? <exp>*</exp> : null}</label>
            <item.field
              type={item.type || "text"}
              {...register(item.name, item.others)}
            />
            {errors[item.name] && <p>{errors[item.name].message}</p>}
          </div>
        ))}

        {/* 
        <div>
          <label>Email</label>
          <input
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
        </div>

        <div>
          <label>Phone Number</label>
          <input
            type="number"
            {...register("phone", {
              required: "Phone number is required",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Phone number must be 10 digits",
              },
            })}
          />
          {errors.phone && <p>{errors.phone.message}</p>}
        </div>

        <div>
          <label>Job Title</label>
          <input
            {...register("jobTitle", {
              required: "Job title is required",
            })}
          />
          {errors.jobTitle && <p>{errors.jobTitle.message}</p>}
        </div>

        <div>
          <label>Message</label>
          <textarea
              rows="4"
            {...register("message", {
              required: "Message is required",
              maxLength: { value: 200, message: "Max 200 characters allowed" },
            })}
          ></textarea>
          {errors.message && <p>{errors.message.message}</p>}
        </div> */}
        <div className="flex justify-center gap-2 sticky bottom-0 !m-0 py-2 bg-white">
          <button
            type="submit"
            className="px-3 py-2 bg-[#800080] text-white border rounded-md hover:border hover:border-[#800080] hover:bg-white hover:text-[#800080]"
          >
            Exchange
          </button>
          <button
            type="button"
            className="px-3 py-2 bg-[#800080] text-white border rounded-md hover:border hover:border-[#800080] hover:bg-white hover:text-[#800080]"
            onClick={() => setIsProfile(null)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default HookFormExchange;
